.btn {
    .btn-check:focus + &,
    &:focus {
        box-shadow: $btn-focus-box-shadow !important;
    }
    &.btn-yellow {
        background-color: $yellow;
        color: $white;
        &:hover,
        &:focus {
            color: $white;
        }
    }
}



