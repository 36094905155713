@import '../bootstrap/mixins';

@mixin more {
    text-transform: uppercase;
    border-width: 0 0 1px 10px;
    border-style: solid;
    text-decoration: none;
    color: $yellow;
    border-color: $yellow;
    position: relative;
    &:focus, 
    &:hover {
        color: $body-color;
        opacity: 1;
        border-color: $body-color;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 10px;
        background: $white;
        z-index: 9;
        left: -10px;
        right: 0;
    }
}