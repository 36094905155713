label, th {
    font-weigth: bold;
    white-space: nowrap;
}
div.w2p_flash {
    background-image: none;
    border-radius: 4px;
    -o-border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #333;
    font-weight: 400;
    min-width: 28px;
    max-width: 300px;
    opacity: 1;
    vertical-align: baseline;
    right: auto;
    border-width: 1px;
    margin: 0 0 20px;
    padding: 15px 35px 15px 15px;
}
.nav-item a {
    white-space: nowrap;
}
div.w2p_flash.alert:hover {
    opacity: 1;
}
.ie-lte8 div.w2p_flash {
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#222222', endColorstr='#000000', GradientType=0);
}
.ie-lte8 div.w2p_flash:hover {
    filter: alpha(opacity=25);
}
.main-container {
    margin-top: 20px;
}

div.error {
    width: auto;
    background: transparent;
    border: none;
    background-image: none;
    color: red;
    display: inline-block;
    padding: 5px;
}
div.w2p_flash.alert {
    display: none;
    position: fixed;
    top: 60px;
    right: 215px;
    cursor: pointer;
    z-index: 1000;
    background-color: #f9edbe;
    border-color: #f0c36d;
}
.w2p-toolbar-hidden {
    margin: 10px;
}
ul.w2p_list {
    margin-left: 0;
    padding-left: 0;
}
.w2p_list li {
    margin-bottom: 6px;
}
.w2p_list li input {
    display: inline-block;
    width: 85%;
    margin-right: 4px;
}
.w2p_list li a {
    margin-bottom: 2px;
}
div[id^=_autocomplete_] {
    margin-top: -10px;
    z-index: 1;
}
select.autocomplete {
    display: block;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    vertical-align: middle;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border-color: #428bca;
    padding: 6px 12px;
}
#web2py-logo {
    color: #c6cecc;
}
#web2py-logo b {
    display: inline-block;
    margin-top: -1px;
}
#web2py-logo b>span {
    font-size: 22px;
    color: #FFF;
}
#web2py-logo:hover {
    color: #FFF;
}
.footer > .row {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
}
html {
    position: relative;
    min-height: 100%;
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: #f7f7f7;
}
header .jumbotron {
    background-color: transparent;
}
.w2p_flash {
    opacity: 0.9!important;
    right: 100px;
}
.right {
    float: right;
    text-align: right;
}
.left {
    float: left;
    text-align: left;
}
.center {
    width: 100%;
    text-align: center;
    vertical-align: middle;
}
td.w2p_fw {
    padding-bottom: 1px;
}
td.w2p_fl {
    text-align: left;
}
td.w2p_fl,
td.w2p_fw {
    padding-right: 7px;
}
td.w2p_fl,
td.w2p_fc {
    padding-top: 4px;
}
div.w2p_export_menu {
    margin: 5px 0;
}
div.w2p_export_menu a,
div.w2p_wiki_tags a,
div.w2p_cloud a {
    margin-left: 5px;
    padding: 2px 5px;
}
#submit_record__row td {
    padding-top: .5em;
}
div.error_wrapper {
    display: block;
}
.copyright {
    float: left;
}
#poweredBy {
    float: right;
}
.web2py_grid tbody td {
    vertical-align: middle;
    padding: 2px 5px;
}
.web2py_grid thead th,
.web2py_grid tfoot td {
    background-color: #EAEAEA;
    padding: 10px 5px;
}
.web2py_grid tr.odd {
    background-color: #F9F9F9;
}
.web2py_grid tr:hover {
    background-color: #F5F5F5;
}
.web2py_console form {
    width: 100%;
    display: inline;
    vertical-align: middle;
    margin: 0 0 0 5px;
}
.web2py_console form select {
    margin: 0;
}
.web2py_search_actions {
    float: left;
    text-align: left;
    width: 100%;
}
.web2py_grid .row_buttons {
    min-height: 25px;
    vertical-align: middle;
}
.web2py_grid .row_buttons a {
    margin: 3px;
}
.web2py_grid .row_buttons a,
.web2py_paginator ul li a,
.web2py_search_actions a,
.web2py_console input[type=submit],
.web2py_console input[type=button],
.web2py_console button {
    margin-right: 2px;
    display: inline-block;
    padding: 6px 12px;
}
.w2p_query_row .btn-default {
    border: 1px #222 solid;
}
.web2py_counter {
    margin-top: 5px;
    margin-right: 2px;
    width: 35%;
    float: right;
    text-align: right;
}
.web2py_table {
    clear: both;
    display: block;
}
.web2py_paginator {
    text-align: right;
    background-color: #f2f2f2;
    padding: 5px;
}
.web2py_paginator ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.web2py_paginator .current {
    font-weight: 700;
}
.web2py_breadcrumbs ul {
    list-style: none;
    margin-bottom: 18px;
}
li.w2p_grid_breadcrumb_elem {
    display: inline-block;
}
.web2py_console input,
.web2py_console select,
.web2py_console a {
    margin: 2px;
    padding: 6px 12px;
}
#wiki_page_body {
    width: 600px;
    height: auto;
    min-height: 400px;
}
.ie-lte7 .topbar .container {
    z-index: 2;
}
.ie9 #w2p_query_panel {
    padding-bottom: 2px;
}
.web2py_console .form-control {
    width: 20%;
    display: inline;
}
.web2py_console #w2p_keywords {
    width: 50%;
}
.web2py_search_actions a,
.web2py_console input[type=submit],
.web2py_console input[type=button],
.web2py_console button {
    padding: 6px 12px;
}
td.w2p_fl,
td.w2p_fw,
td.w2p_fc,
#web2py_user_form td,
.web2py_grid .web2py_form td {
    vertical-align: top;
}
#auth_user_remember__row label,
.web2py_paginator ul li {
    display: inline;
}
.web2py_grid,
.web2py_grid table {
    width: 100%;
}

/* form submit block */
#submit_record__row .btn {
    margin-bottom: .25rem;
}
@media (min-width: 577px) {
    #submit_record__row .btn {
        margin-left: .25rem;
    }
}
@media (max-width: 576px) {
    #submit_record__row .btn {
        width: 100%;
        margin-left: 0;
    }
}

/* for backward compatbility with pre-font-awesome */
.icon.plus,.icon.arrowleft,.icon.download,.icon.trash,.icon.pen,.icon.arrowright,.icon.magnifier {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon.plus:before { content: "\f067";}
.icon.arrowleft:before { content: "\f060";}
.icon.download:before { content: "\f019";}
.icon.trash:before { content: "\f1f8";}
.icon.pen:before { content: "\f040";}
.icon.arrowright:before { content: "\f061";}
.icon.magnifier:before { content: "\f002";}

.web2py_table_selectable_actions {
   padding-top: 10px;
   float: right;
}

.web2py_table_selectable_actions input {
   padding: 5px 7px;
   margin-right: 10px;
}
