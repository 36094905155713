footer {
    background-blend-mode: overlay;
    background-image: url(../images/isrm_icon_darker.svg);
    background-position: top 73% right 35%;
    background-repeat: no-repeat;
    background-size: 67%;
}



