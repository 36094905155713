/*@import '../bootstrap/variables';*/


/* Typography */
$font-family-sans-serif: 'Arial', Helvetica, sans-serif;
$font-family-base: 'Arial', Helvetica, sans-serif;
$font-title: 'Times New Roman', Times, serif;
// h6 starts at $base-font-size 
// headings grow from h6 to h1 by $heading-scale
$base-font-size: 16px;
$heading-scale: 5; // amount of px headings grow from h6 to h1
$enable-negative-margins: true;

/* Colors */

$isrm-blue: #201d4d;
$blue: #0F0F63;
$yellow: #F6AD1E;
$gray: #f6f6f6;
$gray-dark: #58595b;
$input-focus-border-color: $yellow;
$gray-100: #efefef;
$body-color: $gray-dark;
$dark: #211d4d;
$white: #fff;
$secondary: $gray-dark;

/* Links */

$link-color: #0000b3;
$link-decoration: none;
$link-hover-decoration: underline;

/* Grid */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);
 
/* Navbar */

$navbar-light-brand-color: $isrm-blue;
$navbar-light-brand-hover-color: $isrm-blue;
$navbar-light-color: $isrm-blue;
$navbar-light-hover-color: $isrm-blue;


$nav-pills-border-radius: 0;
$nav-pills-link-active-color: $yellow;
$nav-pills-link-active-bg: #fff;
$btn-focus-box-shadow: none;

/* Forms */

$box-shadow-inset: none;
$input-btn-focus-box-shadow: none;

/* Accordion */

$accordion-button-active-bg: $yellow;

/* Slider */

$carousel-control-opacity: 1;
$carousel-control-hover-opacity: .5;

/* Offcanvas */

$offcanvas-horizontal-width: 350px;

/* List Group */

$list-group-item-padding-y: 2rem;
$list-group-item-padding-x: 1rem;