.carousel-control-prev {
  left: -3rem;
}

.carousel-control-next {
  right: -3rem;
}

.carousel {
    .carousel-item {
        height: 372px;
    }
    .carousel-control-prev, 
    .carousel-control-next {
        width: 7%;
    }
    .carousel-caption {
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        padding: 0;
    }
    img.taller {
        height: 100%;
    }
}

.carousel-inner {
    background-color: $isrm-blue;
}

@media (max-width: 768px) {
    .carousel {
        .carousel-item {
            height: 550px;
        }
        img {
            max-height: 250px;
        }
    }
}




