@import '../abstracts/variables';

.navbar-isrm {
    a {
        position: relative;
        &:hover,
        &:focus {
            color: $blue;
            text-decoration: none;
        }
        &::after {
            border-bottom: 4px solid transparent;
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            display: block;
            right: 0;
        }
        &:focus:after, 
        &:hover:after,
        &.active:after {
            border-color: $yellow;
        }
    }
}

@for $i from 1 through 6 {
  h#{$i} {
    font-size: $base-font-size + $heading-scale * (6 - $i) !important;
    font: 100% $font-title;
  }
}

.article-header {
    :is(h1, h2, h3, h4, h5, h6) {
      color: $blue;
    }
}

.resources {
    h6 {
        font: 100% $font-family-base;
        border-bottom: 1px solid $yellow;
        border-left: 10px solid $yellow;
        padding-left: 6px;
        color: $blue;
    }
}


