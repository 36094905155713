header {
    .container-fluid {
        .row {
            position: relative;
            z-index: 1;
        }
        .btn-link {
            text-decoration: none;
        }
        &::before {
            content: "";
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;
            background-color: $yellow;
            background-image: url(../images/isrm_icon.svg);
            background-position: top 18% right 33%;
            background-repeat: no-repeat;
            top: 0;
            z-index: -1;
            background-size: 60%;
        }
        &::after {
            content: '';
            position: absolute;
            border-bottom: 3rem solid #fff;
            border-left: 100vw solid transparent;
            width: 100%;
            height: 100%;
            bottom: -30px;
            left: 0;
        }
        h1 {
            color: $dark;
        }
    }
    .header-search {
        top: 2.2rem;
        background-color: $isrm-blue;
    }
    a {
        &[aria-expanded="true"] {

            color: $isrm-blue;
            .bi-search {
                display: none;
            }
            .bi-x {
                display: block !important;
            }
        }
    }
}
