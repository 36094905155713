@import '../abstracts/mixins';

section {
    padding-top: 4rem;
    padding-bottom: 4rem;  
}




