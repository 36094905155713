.nav-pills {
    .nav-link {
        background: #fff;
        border-radius: $nav-pills-border-radius;
        opacity: .7;
        &:hover {
            border-bottom: 2px solid $yellow;
            opacity: 1;
            background: #fff;
            color: $nav-pills-link-active-color;
        }
    }
    .nav-link.active,
    .show > .nav-link {
        color: $nav-pills-link-active-color;
        background: $nav-pills-link-active-bg;
        border-bottom: 2px solid $yellow;
        opacity: 1;
    }
    li {
        button {
            padding: 1rem;
            &:after {
                @include media-breakpoint-up(sm) {
                    content: '';
                    position: absolute;
                    right: 0;
                    border-right: 2px solid #ccc;
                    top: .5rem;
                    bottom: .5rem;
                }
            }
        }
        &:last-of-type {
            button:after {
                border: none;
            }
        }
    }
}

.navbar-nav {
    .nav-link {
        color: $isrm-blue;
    }
}

header {
    .btn-link {
        color: $isrm-blue;
    }
}


