@import '../abstracts/variables';
@import '../abstracts/mixins';


html {
  box-sizing: border-box;
}

body {
    font-family: $font-family-base;
    overflow-x: hidden;
    & a {
        text-decoration: none;
        &[type=button]:hover,
        &.btn:hover {
            text-decoration: none;
        }
        &:focus,
        &:hover {
            text-decoration: underline;
        }
        &.more {
            @include more;
        }
        &.blue:hover {
            color: $blue;
        }
    }
}



*,
*::before,
*::after {
  box-sizing: inherit;
}

:focus{
   outline: 0;
}

.blue {
    color: $isrm-blue;
}

.yellow {
    color: $yellow;
}

.border-yellow {
    border-color: $yellow !important;
}

.bg-blue {
    background-color: $isrm-blue;
    a {
        color: $yellow;
        &:focus, 
        &:hover {
            color: $yellow;
        }
        &.more {
            &::after {
                background: $isrm-blue;
            }

        }
    }
}

a {
    cursor: pointer;
    &.yellow {
        &:focus,
        &:hover {
        color: $yellow;
        }
    }
}

.bg-light {
    a.more:after{
        background: $gray-100;
    }
}

.members img {
    max-width: 100%;
    padding: .2rem;
    object-fit: contain;
    mix-blend-mode: multiply;
}

.area-title {
    color: $yellow;
    border-left: 15px solid $yellow;
    padding-left: 10px;
    /*line-height: .7;*/
    margin: 0;
} 

.cke_focus {
    td {
        border: #d3d3d3 2px dotted !important;
    }
}

.help-block:empty {
    display: none;
}

ul.index {
    column-count: 1;
}

@include media-breakpoint-up(md) {
    ul.index {
        column-count: 2;
    }
}

@include media-breakpoint-up(lg) {
    ul.index {
        column-count: 3;
    }
}

