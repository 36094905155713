@import 
    'abstracts/abstracts-index',
    'base/base-index',
    'components/components-index',
    'layout/layout-index',
    'bootstrap/bootstrap',
    'bootstrap/bootstrap-grid',
    'bootstrap/bootstrap-reboot',
    'bootstrap/bootstrap-utilities';

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");

.break {
    .row {
        &::before {
        content: "";
        position: absolute;
        border-bottom: 25px solid #fff;
        border-left: 100vw solid transparent;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        z-index: 99999;
        }
        &::after {
        content: "";
        position: absolute;
        border-top: 25px solid #fff;
        border-right: 100vw solid transparent;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        z-index: 99999;
        }
    }
}


.page-block::after {
    content: "";
    clear: both;
    display: block;
}
.page-block:last-child {
    margin-bottom: 2rem;
}

